
import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import CardTextarea from "@/components/Content/CardTextarea.vue";
// @ts-ignore
import VueQRCodeComponent from "vue-qrcode-component";
// @ts-ignore
import { isValidUrl, isRequired } from "@/services/rule-services.ts";
// @ts-ignore
import { Notification, MessageTypes } from "@/interfaces/proccess";
// @ts-ignore
import { replaceVariable } from "@/utils/filter-global";

Vue.component("qr-code", VueQRCodeComponent);
export default Vue.extend({
	name: "ToolForm",
	props: {},
	components: {
		Alertize,
		Separator,
		CardActions,
		VueQRCodeComponent,
		CardTextarea,
	},
	data: () => ({
		valid: true,
		oneLink: "",
		resultOnelink: "",
		scan: false,
	}),
	created() {},
	async mounted() {},
	computed: {
		getRules() {
			return {
				isRequired,
				isValidUrl,
			};
		},
	},
	methods: {
		/**
		 * Validar el formulario
		 */
		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		/**
		 * Handle para el submit del formulario
		 */
		async handleSubmit(event: any) {
			/**
			 * Validar formulario
			 */
			if (!(await this.validate())) return;

			const urlFormatted: URL = await this.replaceUrlParam(this.oneLink);

			const isValid: Boolean =
				urlFormatted.protocol === "http:" ||
				urlFormatted.protocol === "https:";

			this.scan = isValid;

			this.resultOnelink = "";

			if (!isValid) {
				this.setNotification({
					title: this.$t("title-failed"),
					message: this.$t("domain"),
					btn_text: this.$t("try-again"),
					type: MessageTypes.ERROR,
					show: true,
				});
				return;
			}

			this.resultOnelink = urlFormatted.href;
		},

		/**
		 * Esta funcion va a reemplazar los valores que tengan {{ }} por el valor "123456" y devolver
		 */
		async replaceUrlParam(url: string): Promise<URL> {
			// TODO script para reemplazar y retornar resultado

			/**
			 * Convertir el string en una url formateada valida
			 */
			let newUrl: URL = new URL(url);
			/**
			 * Obtener todos los parametros de la url
			 */
			const params = new URLSearchParams(newUrl.search);

			//@ts-ignore
			for (let [key, value] of params.entries()) {
				const clonedValue: string = value;
				const replacedVariable: string = replaceVariable(
					clonedValue,
					123456
				);
				newUrl.searchParams.set(key, replacedVariable);
			}

			return newUrl;
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},
	},

	watch: {
		oneLink(val) {
			if (!val) {
				(this.resultOnelink = null), (this.scan = false);
			}
		},
		resultOnelink(val) {
			if (!val) {
				this.scan = false;
			}
		},
	},
});
