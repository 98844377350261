import Vue from "vue";
//@ts-ignore
import Alertize from "@/components/Alertize.vue";

//@ts-ignore
import ToolForm from "@/views/Admin/ToolsRappi/Form/ToolForm.vue";

export default Vue.extend({
	name: "ToolsRappi",
	props: {},
	components: { Alertize, ToolForm },
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {},
	computed: {},
	methods: {},
});
